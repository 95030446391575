import './App.css';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import Startup from 'pages/startup';
import AuthRouter from 'components/security/AuthRouter';
import PublicRoute from 'components/security/publicRoute';
import PrivateRoute from 'components/security/privateRoute';
import Dashboard from 'pages/home';
import { theme } from 'layouts/theme';
import { useNavigate } from '@reach/router';
import { PublicClientApplication } from '@azure/msal-browser';

export interface IAppProps {
  publicClientApplication: PublicClientApplication
}

function App( {publicClientApplication}: IAppProps) {
  
  return (
    <ThemeProvider theme={theme}>
      <AuthRouter >
        <PublicRoute path="/" component={Startup} />
        <PrivateRoute path="/Home" component={Dashboard} />
      </AuthRouter>
    </ThemeProvider>
  );
}


export default App;