import * as ApiActions from "./apiCaller";
import { config } from "config";
import axios from "axios";

const baseUrl = config.REACT_APP_BASE_API_URL
    + 'Structures';

export function GetStructure(criteria, endFunctionHandler, errorHandler) {

    var currentUrl = baseUrl;
    if (criteria !== null && criteria !== undefined)
        currentUrl += toQueryString(criteria)  ;
    ApiActions.getAPI(currentUrl)
        .then((datas) => {
            if (endFunctionHandler != null)
                endFunctionHandler(datas.data);
        })
        .catch((error) => {
            if (errorHandler != null)
                errorHandler(error);
        });
}

export function GetActivities(endFunctionHandler, errorHandler) {

    var currentUrl = baseUrl + "/Activities";
    ApiActions.getAPI(currentUrl)
        .then((datas) => {
            if (endFunctionHandler != null)
                endFunctionHandler(datas.data);
        })
        .catch((error) => {
            if (errorHandler != null)
                errorHandler(error);
        });
}

export function GetZones(activityId, endFunctionHandler, errorHandler) {

    var currentUrl = baseUrl + "/Zones?activityId=" + activityId;
    ApiActions.getAPI(currentUrl)
        .then((datas) => {
            if (endFunctionHandler != null)
                endFunctionHandler(datas.data);
        })
        .catch((error) => {
            if (errorHandler != null)
                errorHandler(error);
        });
}

export function GetBus(zoneId, endFunctionHandler, errorHandler) {

    var currentUrl = baseUrl + "/Bus?zoneId=" + zoneId;
    ApiActions.getAPI(currentUrl)
        .then((datas) => {
            if (endFunctionHandler != null)
                endFunctionHandler(datas.data);
        })
        .catch((error) => {
            if (errorHandler != null)
                errorHandler(error);
        });
}

export function GetSjs(buId, endFunctionHandler, errorHandler) {

    var currentUrl = baseUrl + "/Sjs?buId=" + buId;
    ApiActions.getAPI(currentUrl)
        .then((datas) => {
            if (endFunctionHandler != null)
                endFunctionHandler(datas.data);
        })
        .catch((error) => {
            if (errorHandler != null)
                errorHandler(error);
        });
}

export function GetUes(sjId, endFunctionHandler, errorHandler) {

    var currentUrl = baseUrl + "/Ues?sjId=" + sjId;
    ApiActions.getAPI(currentUrl)
        .then((datas) => {
            if (endFunctionHandler != null)
                endFunctionHandler(datas.data);
        })
        .catch((error) => {
            if (errorHandler != null)
                errorHandler(error);
        });
}

function toQueryString(criteria){
    var separator = "?";
    var request = "";

    if(criteria !== null
        && criteria !== undefined){
        if(criteria.ue !== null
            && criteria.ue !== undefined){
                request += separator + "ue=" + criteria.ue;
                separator = "&";
        }
        if(criteria.sj !== null
            && criteria.sj !== undefined){
                request += separator + "sj=" + criteria.sj;
                separator = "&";
        }
        if(criteria.activity !== null
            && criteria.activity !== undefined){
                request += separator + "activity=" + criteria.activity;
                separator = "&";
        }
        if(criteria.zone !== null
            && criteria.zone !== undefined){
                request += separator + "zone=" + criteria.zone;
                separator = "&";
        }
        if(criteria.bu !== null
            && criteria.bu !== undefined){
                request += separator + "bu=" + criteria.bu;
                separator = "&";
        }
        if(criteria.ert !== null
            && criteria.ert !== undefined){
                request += separator + "ert=" + criteria.ert;
                separator = "&";
        }
    }
    return request;
}