import { DoneOutlined, Engineering, Search, SearchOffOutlined } from "@mui/icons-material";
import { Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Link } from "@reach/router";
import MainLayout from "layouts/mainLayout";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import { GetUser, PostUser } from "services/userManagementApiCaller";
import { GetStructure } from "services/structureApiCaller";
import { store } from '../store/rootStore';
import { StructureSelector } from "components/structure/structureSelector";

export default function Home({ }) {

  var state = store.getState();
  const [login, setLogin] = useState(null);
  const preferredName = state.authentReducer.preferredName.replace("@colas.com", "");
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({});
  const [structure, setStructure] = useState({});
  const [isStrucutreSelectorOpen, setIsStrucutreSelectorOpen] = useState(false);

  const employeeLevels = [
    { id: "OV", label: "Ouvier" },
    { id: "ET", label: "ETAM" },
    { id: "IIA", label: "Cadre" }
  ]


  useEffect(() => {
    if (isFirstTime) {
      getUser();
      setIsFirstTime(false);
    }
  }, isFirstTime);

  function handleChage(property, value) {
    var tempUser = {};
    tempUser[property] = value;
    setUser({ ...user, ...tempUser });
  }

  function getUser() {
    setIsLoading(true);
    var searchedLogin = preferredName;
    if (login !== null && login.length > 0)
      searchedLogin = login;
    GetUser(searchedLogin,
      (data) => {
        setUser(data);
        getStructure(data);
      },
      () => { setIsLoading(false); })
  }

  function getStructure(user) {
    GetStructure({ 'ue': user.assignmentUeIdentifier },
      (data) => {
        if (data.result.length > 0)
          setStructure(data.result[0]);
        else
          setStructure({});
        setIsLoading(false);
      },
      () => { setIsLoading(false); })
  }

  function validateUser() {
    setIsLoading(true);
    PostUser(user,
      () => { getUser(); },
      () => { setIsLoading(false); })
  }

  var content = <ClipLoader />;
  if (!isLoading)
    content =
      <>
        <Box>
          <form onSubmit={getUser}>
            <span style={{ paddingRight: '5px' }}>Login</span>
            <input value={login} onChange={(e) => setLogin(e.currentTarget.value)} />
            <IconButton
              onClick={getUser}>
              <SearchOffOutlined />
            </IconButton>
          </form>
        </Box>
        <Card
          sx={{ width: "500px" }}>
          <CardHeader
            title={user.login} />
          <CardContent>
            <TextField
              disabled
              sx={{ width: "100%", marginTop: "10px" }}
              label="Direction"
              value={structure.DIR_LEVEL3_LABEL + " / " + structure.DIR_LEVEL4_LABEL}
            />
            <TextField
              disabled
              sx={{ width: "100%", marginTop: "10px" }}
              label="Sj"
              value={structure.STC_COUNTRY_LABEL + " / " + structure.STC_SJ_LABEL}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}>
              <TextField
              disabled
                sx={{ width: "100%", marginTop: "10px" }}
                label="UE"
                value={structure.STC_UE_LABEL}
              />
              <IconButton onClick={() => setIsStrucutreSelectorOpen(true)}>
                <Search />
              </IconButton>
            </div>
            <FormControl fullWidth
              sx={{ marginTop: "10px" }}>
              <InputLabel
                id="employeeLevel-label"
                style={{ backgroundColor: "white" }}
              >
                Code niveau employé
              </InputLabel>
              <Select
                labelId="employeeLevel-label"
                value={user.employeeLevelCode}
                onChange={(e) => {
                  handleChage("employeeLevelCode", e.target.value);
                }}
              >
                <MenuItem value={'OV'}>Ouvier</MenuItem>
                <MenuItem value={'ET'}>Etam</MenuItem>
                <MenuItem value={'IIA'}>Cadre</MenuItem>
              </Select>
            </FormControl>
            <TextField
              sx={{ width: "100%", marginTop: "10px" }}
              label="Application source"
              value={user.sourceApplication}
              onChange={(e) => handleChage("sourceApplication", e.currentTarget.value)}
            />
            <FormControlLabel
              sx={{ marginLeft: "5px", marginTop: "10px" }}
              control={<Checkbox checked={user.isEnabled} onChange={(e) => handleChage("isEnabled", e.currentTarget.checked)} />}
              label="Est actif"
            />
            <FormControlLabel
              sx={{ marginLeft: "5px", marginTop: "10px" }}
              control={<Checkbox checked={user.isManager} onChange={(e) => handleChage("isManager", e.currentTarget.checked)} />}
              label="Est manager"
            />
          </CardContent>
          <CardActions disableSpacing
            sx={{ display: "flex", alignContent: "center" }}>
            <Button
              variant="contained"
              endIcon={<DoneOutlined />}
              onClick={validateUser}>
              Valider
            </Button>
          </CardActions>
        </Card>
        <StructureSelector
          isOpen={isStrucutreSelectorOpen}
          handleValidate={(ue) =>
            {
              handleChage("assignmentUeIdentifier", ue);
              getStructure({ assignmentUeIdentifier: ue });
              setIsStrucutreSelectorOpen(false);
            } 
          }
          handleClose={() => setIsStrucutreSelectorOpen(false)} />
      </>
  return (
    <>
      <MainLayout>
        <Box
          alignItems="center"
          width="100%"
          display="flex"
          flexDirection="column"
          marginTop="50px">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center">
            {content}
          </Box>
        </Box>
      </MainLayout>
    </>
  );
}
