import * as ApiActions from "./apiCaller";
import { config } from "config";
import axios from "axios";

const baseUrl = config.REACT_APP_BASE_API_URL
    + 'UserManagement';

export function GetUser(login, endFunctionHandler, errorHandler) {

    var currentUrl = baseUrl;
    if (login !== null && login !== undefined)
        currentUrl += "?login=" + login;
    ApiActions.getAPI(currentUrl)
        .then((datas) => {
            var json = {login: login};
            if (datas !== null
                && datas !== undefined)
                json = datas.data;
            if (endFunctionHandler != null)
                endFunctionHandler(json);
        })
        .catch((error) => {
            if (errorHandler != null)
                errorHandler(error);
        });
}
export function PostUser(item, successHandler, errorHandler) {

    var response = ApiActions.postAPI(baseUrl, JSON.stringify(item))
        .then((datas) => {
            if (successHandler != null)
                successHandler();
        })
        .catch((error) => {
            var errorMessage = "Une erreur s'est produite.";
            if (error.response.status == 400)
                errorMessage = "Les données sont invalides";
            if (errorHandler != null)
                errorHandler(errorMessage);
            console.error(error);
        });
}