import { AccountInfo } from '@azure/msal-browser';
import { ActionsTypes } from 'store/types/actionTypes';

export function SetIdToken(token: string){
    return (dispatch: any) => {
        
        dispatch({
            type: ActionsTypes.IdToken,
            idToken: token
        });
    };
}


export function SetAccessToken(token: string){
    return (dispatch: any) => {
        
        dispatch({
            type: ActionsTypes.AccessToken,
            accessToken: token
        });
    };
}
export function SetAccountInfo(accountInfo: AccountInfo){
    return (dispatch: any) => {
        
        dispatch({
            type: ActionsTypes.AccountInfo,
            accountInfo: accountInfo
        });
    };
}