import { createTheme, TableCell, Typography } from "@mui/material";
import { withStyles, makeStyles } from '@mui/styles';
import { grey } from "@mui/material/colors";

export const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: "#EEE6D8",
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#EEE6D8"
        }
      }
    }
  }
});



