import { Router } from "@reach/router";
import { useEffect } from "react";
import { connect } from "react-redux";
import * as AuthActions from '../../store/actions/authActions';
import { useAccount, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { tokenRequest } from "authConfig";
import { AccountInfo, InteractionRequiredAuthError, InteractionType } from "@azure/msal-browser";
import { IdTokenResponse } from "react-aad-msal";

export interface IAuthRouteProps {
    children: any,
    name: string,
    setIdToken: (token: string)=>void,
    setAccessToken: (token: string)=>void,
    setAccountInfo: (accountInfo: AccountInfo)=>void,
}

function AuthRouter({ children, name, setIdToken, setAccessToken, setAccountInfo }: IAuthRouteProps) {
    const {login, result, error} = useMsalAuthentication(InteractionType.Silent, tokenRequest);
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    useEffect(() => {
        if (account){
            setAccountInfo(account);
        }
    })

    useEffect(() => {
        if (error instanceof InteractionRequiredAuthError) {
            login(InteractionType.Redirect, tokenRequest);
        }
    }, [error, login]);

    useEffect(() => {
        if (result?.idToken){
            setIdToken(result.idToken);
        }
        if (result?.accessToken){
            setAccessToken(result.accessToken)
        }
    }, [result, setIdToken, setAccessToken])
    
//     useEffect(() => {
//       if (account) {
//           instance.acquireTokenSilent({
//               scopes: tokenRequest.scopes,
//               account: account
//           }).then((response) => {
//               if(response) {
//                   setAccessToken(response.accessToken);
//               }
//           });
//       }
//   }, [account, instance, setAccessToken, setIdToken]);

    return (
        <Router>
            {children}
        </Router>
    );
}


const mapStateToProps = (state: any) => {
    return {
        name: state.authentReducer.name,
    };
};

const mapDispatchToProps = {
    setIdToken: AuthActions.SetIdToken,
    setAccessToken: AuthActions.SetAccessToken,
    setAccountInfo: AuthActions.SetAccountInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthRouter);

