import { Box, Typography } from "@mui/material";
import { Redirect } from "@reach/router";
import React, { useState, useRef, useEffect, ReactElement } from "react";
import { connect } from "react-redux";
import CircleLoader from "react-spinners/CircleLoader";


const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};

function Startup({ isAuthenticated, ...rest }) {

    const [cacheReady, setCacheReady] = useState(false);

    var authentComponent = (
        <Typography
            sx={{ marginTop: "50px", color: "black", fontSize: "25px" }}
        >Authentification pending...</Typography>
    );

    function handleLoadComplete() {
        setCacheReady(true);
    }
    function handleError(errors) {

    }

    if (isAuthenticated) {
        authentComponent = (
            <Redirect to="/Home" />
        );
    }

    return (
        <Box
            alignItems="center"
            justifyContent="center"
            height="100vh"
            display="flex"
            flexDirection="column"
            sx={{ backgroundColor: "#E5E7E6" }}
        >
            <CircleLoader color="black" loading="true" cssOverride={override} size={150} />
            {authentComponent}
        </Box>
    );

}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.authentReducer.isAuthenticated
    };
};


export default connect(mapStateToProps)(Startup);