import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { GetActivities, GetBus, GetSjs, GetUes, GetZones } from "services/structureApiCaller";
import React, { useEffect, useState } from "react";
import { Check } from "@mui/icons-material";




export function StructureSelector({ isOpen, handleValidate, handleClose }) {
    const [activities, setActivities] = React.useState([]);
    const [zones, setZones] = React.useState([]);
    const [bus, setBus] = React.useState([]);
    const [sjs, setSjs] = React.useState([]);
    const [ues, setUes] = React.useState([]);

    const [selectedActivity, setSelectedActivity] = React.useState('');
    const [selectedZone, setSelectedZone] = React.useState('');
    const [selectedBu, setSelectedBu] = React.useState('');
    const [selectedSj, setSelectedSj] = React.useState('');
    const [selectedUe, setSelectedUe] = React.useState('');

    const [isFirstTime, setIsFirstTime] = useState(true);


    useEffect(() => {
        if (isFirstTime) {
            setIsFirstTime(false);
            GetActivities((data) => {
                setActivities(data.result);
            });
        }
    }, isFirstTime);

    function TansformToMenuItems(items) {
        return items.map((item) => <MenuItem value={item.id}>{item.name}</MenuItem>);
    }

    function Validate() {
        if (handleValidate)
            handleValidate(selectedUe);
    }

    function handleActivityChange(event) {
        setSelectedActivity(event.target.value);
        setSelectedZone('');
        setSelectedBu('');
        setSelectedSj('');
        setSelectedUe('');
        GetZones(event.target.value, (data) => {
            setZones(data.result);
        });
    }

    function handleZoneChange(event) {
        setSelectedZone(event.target.value);
        setSelectedBu('');
        setSelectedSj('');
        setSelectedUe('');
        GetBus(event.target.value, (data) => { setBus(data.result); });
    }

    function handleBuChange(event) {
        setSelectedBu(event.target.value);
        setSelectedSj('');
        setSelectedUe('');
        GetSjs(event.target.value, (data) => { setSjs(data.result); });
    }

    function handleSjChange(event) {
        setSelectedSj(event.target.value);
        setSelectedUe('');
        GetUes(event.target.value, (data) => { setUes(data.result); });
    }

    function handleUeChange(event) {
        setSelectedUe(event.target.value);
    }

    function setValidateEnabling() {
        if (selectedUe)
            return false;
        return true;
    }

    function showText() {
        if (selectedUe)
            return (
                <></>
            );
        return (
            <Typography
            style={{
                color: "red",
                marginRight: "12px",
                fontWeight: "bold",
                fontSize: "10px",
                fontStyle: "italic",
            }}>
                Veuillez sélectionner une unité d'exploitation
                </Typography>
        );
    }

    return (
        <Dialog open={isOpen} onClose={() => handleClose()}>
            <DialogTitle>
                Sélection d'uns structure de rattachements
            </DialogTitle>
            <DialogContent>
                <div
                    style={{
                        width: "400px",
                    }}>
                    <FormControl fullWidth
                        sx={{ marginTop: "10px" }}>
                        <InputLabel
                            id="activity-label"
                            style={{ backgroundColor: "white" }}
                        >
                            Activité
                        </InputLabel>
                        <Select
                            labelId="activity-label"
                            onChange={handleActivityChange}
                            value={selectedActivity}
                        >
                            {TansformToMenuItems(activities)}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth
                        sx={{ marginTop: "10px" }}>
                        <InputLabel
                            id="zone-label"
                            style={{ backgroundColor: "white" }}
                        >
                            Zone
                        </InputLabel>
                        <Select
                            labelId="zone-label"
                            value={selectedZone}
                            onChange={handleZoneChange}
                        >
                            {TansformToMenuItems(zones)}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth
                        sx={{ marginTop: "10px" }}>
                        <InputLabel
                            id="bu-label"
                            style={{ backgroundColor: "white" }}
                        >
                            Bu
                        </InputLabel>
                        <Select
                            labelId="bu-label"
                            value={selectedBu}
                            onChange={handleBuChange}
                        >
                            {TansformToMenuItems(bus)}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth
                        sx={{ marginTop: "10px" }}>
                        <InputLabel
                            id="sj-label"
                            style={{ backgroundColor: "white" }}
                        >
                            Sj
                        </InputLabel>
                        <Select
                            labelId="sj-label"
                            value={selectedSj}
                            onChange={handleSjChange}
                        >
                            {TansformToMenuItems(sjs)}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth
                        sx={{ marginTop: "10px" }}>
                        <InputLabel
                            id="ue-label"
                            style={{ backgroundColor: "white" }}
                        >
                            Ue
                        </InputLabel>
                        <Select
                            labelId="ue-label"
                            value={selectedUe}
                            onChange={handleUeChange}
                        >
                            {TansformToMenuItems(ues)}
                        </Select>
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions>
                {showText()}
                <Button
                    variant="outlined"
                    endIcon={<Check />}
                    disabled={setValidateEnabling()}
                    onClick={() => Validate()}>
                    Valider
                </Button>
            </DialogActions>
        </Dialog>
    )
}